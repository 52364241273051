// in app.js
import * as React from "react";
import { Admin as ReactAdmin, Resource, fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import { DCList, DCEdit, DCCreate } from "./admin/DC";

import { ItemList, ItemEdit, ItemCreate, BomItemList, BomItemEdit, BomItemCreate } from "./admin/Items";
import { ItemFeeList, ItemFeeEdit, ItemFeeCreate } from "./admin/ItemFee";
import { ItemDiscountList, ItemDiscountEdit, ItemDiscountCreate } from "./admin/ItemDiscount";
import { ItemCategoryList, ItemCategoryEdit, ItemCategoryCreate } from "./admin/ItemCategories";
import { ItemCategoryFeeList, ItemCategoryFeeEdit, ItemCategoryFeeCreate } from "./admin/ItemCategoryFee";
import { BankList, BankEdit, BankCreate } from "./admin/Banks";
import { ProvinceList, ProvinceEdit, ProvinceCreate } from "./admin/Provinces";
import { DistrictList, DistrictEdit, DistrictCreate } from "./admin/Districts";
import { SubDistrictList, SubDistrictEdit, SubDistrictCreate } from "./admin/SubDistricts";
import { TemplateList, TemplateEdit, TemplateCreate } from "./admin/Templates";
import { ClientStationList, ClientStationEdit, ClientStationCreate } from "./admin/ClientStations";
import { BuyerList, BuyerEdit, BuyerCreate } from "./admin/Buyers";
import { DistanceMatrixList, DistanceMatrixEdit, DistanceMatrixCreate } from "./admin/DistanceMatrixes";
import { UserList, UserEdit, UserCreate } from "./admin/Users";
import { ZoneList, ZoneEdit, ZoneCreate } from "./admin/Zones";
import { VendorList, VendorEdit, VendorCreate } from "./admin/Vendors";
import { ContractList, ContractEdit, ContractCreate } from "./admin/Contracts";
import { ContractItemCategoryList, ContractItemCategoryEdit, ContractItemCategoryCreate } from "./admin/ContractItemCategories";
import { ContractItemList, ContractItemEdit, ContractItemCreate } from "./admin/ContractItems";
import { AmStructureChartList, AmStructureChartEdit, AmStructureChartCreate } from "./admin/AmStructureCharts";
import { EngStructureChartList, EngStructureChartEdit, EngStructureChartCreate } from "./admin/EngStructureCharts";
import { MaintenanceStructureChartList, MaintenanceStructureChartEdit, MaintenanceStructureChartCreate } from "./admin/MaintenanceStructureCharts";
import { WarrantyCodeList, WarrantyCodeEdit, WarrantyCodeCreate } from "./admin/WarrantyCodes";
import Dashboard from "./admin/Dashboard";
import MyLayout from "./admin/MyLayout";

// Functions
import { isExpired } from "../functions/Auth";

// Styles
import "../styles/admin.css";

// Translation
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";

const customMessages = {
  en: {
    resources: {
      item_categories: {
        name: "หมวดหมู่สินค้า",
      },
      items: {
        name: "สินค้า",
      },
      bom_items: {
        name: "Materials BOM",
      },
      buyers: {
        name: "ผู้ซื้อ",
      },
      client_stations: {
        name: "สถานี",
      },
      templates: {
        name: "Template",
      },
      distance_matrixes: {
        name: "ระยะทางระหว่างจังหวัด",
      },
      provinces: {
        name: "จังหวัด",
      },
      districts: {
        name: "เขต/อำเภอ",
      },
      sub_districts: {
        name: "แขวง/ตำบล",
      },
      banks: {
        name: "ธนาคาร",
      },
      users: {
        name: "ผู้ใช้งานระบบ",
      },
      zones: {
        name: "พื้นที่รับผิดชอบ",
      },
      vendors: {
        name: "ชื่อผู้ผลิต",
      },
      contracts: {
        name: "สัญญาคู่ค้า",
      },
      contract_item_categories: {
        name: "สัญญาคู่ค้า (หมวดหมู่)",
      },
      am_structure_charts: {
        name: "โครงสร้าง AM",
      },
      eng_structure_charts: {
        name: "โครงสร้าง วฟ.",
      },
      maintenance_structure_charts: {
        name: "โครงสร้าง ซฟ.",
      },
      warranty_codes: {
        name: "รหัสทรัพย์สิน",
      },
    },
  },
};
const messages = {
  en: { ...englishMessages, ...customMessages.en },
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale]);
export class Admin extends React.Component {
  constructor(props) {
    super(props);
    let model = props.match.params.model;
    this.state = {
      model: model,
    };
  }

  render() {
    if (!this.props.is_admin) {
      return <Redirect to="/error/access_denied" />;
    }

    const convertFileToBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    const httpClient = (url, options = {}) => {
      if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
      }
      // options.headers.set('Authorization', `${token}`);
      return Auth.currentSession()
        .then((session) => {
          const refreshToken = session.getRefreshToken();
          if (isExpired(session)) {
            console.log("expired");
            Auth.currentAuthenticatedUser().then((res) => {
              console.log("refreshing token");
              res.refreshSession(refreshToken, (err, data) => {
                if (err) {
                  console.log("bye");
                  Auth.signOut();
                } else {
                  console.log("===== ok");
                  options.headers.set("Authorization", data.getIdToken().getJwtToken());
                  return fetchUtils.fetchJson(url, options);
                }
              });
            });
          } else {
            options.headers.set("Authorization", session.getIdToken().getJwtToken());
            return fetchUtils.fetchJson(url, options);
          }
        })
        .catch((e) => {
          // No logged-in user: don't set auth header
          console.log(e);
          // Auth.signOut()
        });
    };
    const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient, "X-Total-Count");

    // const authProvider = {
    //   // authentication
    //   login: params => Promise.resolve(),
    //   checkError: (error) => {
    //     window.location.reload();
    //   },
    //   checkAuth: params => Promise.resolve(),
    //   logout: () => Promise.resolve(),
    //   getIdentity: () => Promise.resolve(),
    //   // authorization
    //   getPermissions: params => Promise.resolve(),
    // }

    const myDataProvider = {
      ...dataProvider,
      update: (resource, params) => {
        if (resource !== "templates") {
          // fallback to the default implementation
          return dataProvider.update(resource, params);
        }
        /**
         * For templates update only, convert uploaded image in base 64 and attach it to
         * the `picture` sent property, with `src` and `title` attributes.
         */
        // Freshly dropped pictures are File objects and must be converted to base64 strings
        const newPictures = [];
        var oldPictures = {
          quo_logo: { url: params.data.quo_logo.src, is_updated: false },
          mr_logo: { url: params.data.mr_logo.src, is_updated: false },
          mr_signature: { url: params.data.mr_signature.src, is_updated: false },
          mr_inspection_s3_sign_url: { url: params.data.mr_inspection_s3_sign_url.src, is_updated: false },
        };
        var index = 0;
        if (params.data.quo_logo.rawFile) {
          newPictures.push(params.data.quo_logo);
          oldPictures.quo_logo.is_updated = true;
          oldPictures.quo_logo.url = index;
          index += 1;
        }
        if (params.data.mr_logo.rawFile) {
          newPictures.push(params.data.mr_logo);
          oldPictures.mr_logo.is_updated = true;
          oldPictures.mr_logo.url = index;
          index += 1;
        }
        if (params.data.mr_signature.rawFile) {
          newPictures.push(params.data.mr_signature);
          oldPictures.mr_signature.is_updated = true;
          oldPictures.mr_signature.url = index;
          index += 1;
        }
        if (params.data.mr_inspection_s3_sign_url.rawFile) {
          newPictures.push(params.data.mr_inspection_s3_sign_url);
          oldPictures.mr_inspection_s3_sign_url.is_updated = true;
          oldPictures.mr_inspection_s3_sign_url.url = index;
          index += 1;
        }

        return Promise.all(newPictures.map(convertFileToBase64))
          .then((base64Pictures) =>
            base64Pictures.map((picture64) => ({
              src: picture64,
              title: `${params.data.title}`,
            }))
          )
          .then((transformedNewPictures) =>
            dataProvider.update(resource, {
              ...params,
              data: {
                ...params.data,
                quo_logo: oldPictures.quo_logo.is_updated ? transformedNewPictures[oldPictures.quo_logo.url].src : oldPictures.quo_logo.url,
                mr_logo: oldPictures.mr_logo.is_updated ? transformedNewPictures[oldPictures.mr_logo.url].src : oldPictures.mr_logo.url,
                mr_signature: oldPictures.mr_signature.is_updated ? transformedNewPictures[oldPictures.mr_signature.url].src : oldPictures.mr_signature.url,
                mr_inspection_s3_sign_url: oldPictures.mr_inspection_s3_sign_url.is_updated ? transformedNewPictures[oldPictures.mr_inspection_s3_sign_url.url].src : oldPictures.mr_inspection_s3_sign_url.url,
              },
            })
          );
      },
    };

    return (
      <ReactAdmin layout={MyLayout} dashboard={Dashboard} dataProvider={myDataProvider} i18nProvider={i18nProvider}>
        <Resource name="item_categories" list={ItemCategoryList} edit={ItemCategoryEdit} create={ItemCategoryCreate} />
        <Resource name="items" list={ItemList} edit={ItemEdit} create={ItemCreate} />
        <Resource name="dc_admin" list={DCList} edit={DCEdit} />
        <Resource name="bom_items" list={BomItemList} edit={BomItemEdit} create={BomItemCreate} />
        <Resource name="buyers" list={BuyerList} edit={BuyerEdit} create={BuyerCreate} />
        <Resource name="client_stations" list={ClientStationList} edit={ClientStationEdit} create={ClientStationCreate} />
        <Resource name="templates" list={TemplateList} edit={TemplateEdit} create={TemplateCreate} />
        <Resource name="distance_matrixes" list={DistanceMatrixList} edit={DistanceMatrixEdit} create={DistanceMatrixCreate} />
        <Resource name="provinces" list={ProvinceList} edit={ProvinceEdit} create={ProvinceCreate} />
        <Resource name="districts" list={DistrictList} edit={DistrictEdit} create={DistrictCreate} />
        <Resource name="sub_districts" list={SubDistrictList} edit={SubDistrictEdit} create={SubDistrictCreate} />
        <Resource name="banks" list={BankList} edit={BankEdit} create={BankCreate} />
        <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
        <Resource name="zones" list={ZoneList} edit={ZoneEdit} create={ZoneCreate} />
        <Resource name="vendors" list={VendorList} edit={VendorEdit} create={VendorCreate} />
        <Resource name="contracts" list={ContractList} edit={ContractEdit} create={ContractCreate} />
        <Resource name="contract_item_categories" list={ContractItemCategoryList} edit={ContractItemCategoryEdit} create={ContractItemCategoryCreate} />
        <Resource name="contract_items" list={ContractItemList} edit={ContractItemEdit} create={ContractItemCreate} />
        <Resource name="am_structure_charts" list={AmStructureChartList} edit={AmStructureChartEdit} create={AmStructureChartCreate} />
        <Resource name="eng_structure_charts" list={EngStructureChartList} edit={EngStructureChartEdit} create={EngStructureChartCreate} />
        <Resource name="maintenance_structure_charts" list={MaintenanceStructureChartList} edit={MaintenanceStructureChartEdit} create={MaintenanceStructureChartCreate} />
        <Resource name="warranty_codes" list={WarrantyCodeList} edit={WarrantyCodeEdit} create={WarrantyCodeCreate} />
        <Resource name="item_fees" list={ItemFeeList} edit={ItemFeeEdit} create={ItemFeeCreate} />
        <Resource name="item_discounts" list={ItemDiscountList} edit={ItemDiscountEdit} create={ItemDiscountCreate} />
        <Resource name="item_category_fees" list={ItemCategoryFeeList} edit={ItemCategoryFeeEdit} create={ItemCategoryFeeCreate} />
      </ReactAdmin>
    );
  }
}
