import React from "react";
import { withRouter } from "react-router-dom";

// Models
import { REPORT_TYPES } from "../models/Reports";

// Components
import OrderReport from "../components/report/OrderReport";
import ClientStationReport from "../components/report/ClientStationReport";
import ContractBudgetReport from "../components/report/ContractBudgetReport";
import DCInventoryReport from "../components/report/DCInventoryReport";
// import VendorReport from '../components/report/VendorReport';

import "../styles/quotation.css";
import "../styles/order.css";
import "../styles/report.css";

class Report extends React.Component {
  constructor(props) {
    super(props);
    let selected_report = props.match.params.report;
    this.state = {
      current_page: selected_report ? selected_report : REPORT_TYPES.order.value,
    };
  }

  render() {
    switch (this.state.current_page) {
      case REPORT_TYPES.order.path:
        return <OrderReport current_user={this.props.current_user} />;
      case REPORT_TYPES.client_station.path:
        return <ClientStationReport />;
      case REPORT_TYPES.contract_budget.path:
        return <ContractBudgetReport />;
      case REPORT_TYPES.inventory_report.path:
        return <DCInventoryReport props={this.props} />;

      // case REPORT_TYPES.vendor.path:
      //   return (
      //     <VendorReport/>
      //   );
      default:
        break;
    }
  }
}
export default withRouter(Report);
