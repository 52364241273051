import React, { useState, useEffect } from "react";
import DCLogTable from "./DCLogTable.jsx";
import Chart from "./Chart.jsx";
import {
  APP_TITLE_REPORT_INVENTORY,
} from "../../../constants.js";
import { DatePicker, Select } from "antd";
import "./DcInventoryReport.css";
import Core from "../../../functions/core";
import moment from "moment";
import _ from "lodash";

export default function DcInventoriesManagementIndex({ props }) {
  const [dcProductInbound, setDcProductInbound] = useState([]);
  const [routeFrom, setrouteFrom] = useState(null);
  const [routeTo, setrouteTo] = useState(null);
  const [routeDc, setRouteDc] = useState(null);
  const [dcInventoryLog, setDcInventoryLog] = useState([]);


  useEffect(() => {
    getParamsQuery()
    getOptionsDC()
    getDcInventoryLog()

  }, []);



  async function getDcInventoryLog() {

    let id = new URLSearchParams(props.location.search).get("item_id")
    let from = new URLSearchParams(props.location.search).get("from")
    let to = new URLSearchParams(props.location.search).get("to")
    if (id) {
      let listDC = await getOptionsDC();
      let params = {
        filter: { "created_at": [from, to] },
      }
      let data = await Core.getWithData(`${process.env.REACT_APP_API_URL}/inventory_log/agg/${id}`, { params: params });
      try {
        if (data.records) {
          let dataTable = _.map(data.records, (record) => {
            let obj = record
            let dc = _.find(listDC, (dc) => { return Number(dc.value) == Number(record.item_id) })
            let date = moment(record.date).format("DD/MM/YYYY")
            obj.name = date
            obj.item_name = (dc) ? dc.label : "-"
            obj.date = date
            obj.csp = record.changed_stock_percent
            obj.changed_stock_percent = `${(record.changed_stock_percent) ? record.changed_stock_percent.toFixed(2) : '-'} %`
            obj.cap = record.changed_added_percent
            obj.changed_added_percent = `${(record.changed_added_percent) ? record.changed_added_percent.toFixed(2) : '-'} %`
            obj.crp = record.changed_removed_percent
            obj.changed_removed_percent = `${(record.changed_removed_percent) ? record.changed_removed_percent.toFixed(2) : '-'} %`

            return obj
          });
          setDcInventoryLog(dataTable);
        }
      } catch (error) {

      }
    }

  }


  function getParamsQuery() {
    setrouteFrom(new URLSearchParams(props.location.search).get("from"))
    setrouteTo(new URLSearchParams(props.location.search).get("to"))
    setRouteDc(new URLSearchParams(props.location.search).get("item_id"))
  }

  async function getOptionsDC() {
    try {
      let listDC = await Core.getWithData(`${process.env.REACT_APP_API_URL}/inventory_index`, {});
      if (listDC.data) {
        listDC = _.map(listDC.data, (dc) => { return { value: dc.item_id, label: dc.name } })
        setDcProductInbound(listDC);
      }
      return listDC;
    } catch (error) {
      return [];
    }
  }

  function handleSubmit(event) {
    let checkForm = (routeFrom && routeTo && routeDc) ? true : false;
    if (checkForm) {
      let dc = routeDc
      let from = moment(routeFrom).format("YYYY-MM-DD")
      let to = moment(routeTo).format("YYYY-MM-DD")
      let params = `?item_id=${dc}&from=${from}&to=${to}`
      props.history.replace({
        pathname: `${props.location.pathname}`,
        search: `${params}`,
      });
    } else {
      alert("กรุณากรอกข้อมูลให้ครบถ้วน")
    }
  }

  function clearForm() {
    setrouteFrom(null);
    setrouteTo(null);
    setRouteDc(null);
    props.history.replace({
      pathname: `${props.location.pathname}`,
      search: ``,
    });
  }



  return (
    <div className="offset-xl-1 col-xl-10 p-3 home-scrollable">
      <div className="row align-items-end">
        <div className="col-12 pb-2 d-flex flex-row align-items-end">
          <span className="header-title d-md-block my-0">
            {APP_TITLE_REPORT_INVENTORY}
          </span>
        </div>
      </div>
      <div className="card-box light p-3">
        <form onSubmit={handleSubmit}>
          {/* <SearchBar
            onEnter={this.onEnter}
            onChangeQuery={this.onChangeQuery}
            onClickClear={this.onClickClear}
            query={this.state.query}
            placeholder={"ค้นหาเลขที่ใบเสนอราคา, สถานี, ผู้ซื้อ"}
          /> */}

          <div className="row">
            <div className="col-4 col-md-2 mt-2">
              <label>สินค้า</label>
            </div>
            <div className="col-8 col-md-10 mt-2 date-picker-wrapper">
              <Select className="w-100" showSearch optionFilterProp='label' value={_.find(dcProductInbound, (dc) => { return dc.value == routeDc })}

                placeholder={"ค้นหา..."} options={dcProductInbound} onChange={(e) => { setRouteDc(e) }} />
              {/* onChange={(data)=>{ getQueryDC(data)}} */}
            </div>

          </div>
          <div className="row">
            <div className="col-4 col-md-2 mt-2">
              <label>วันที่เริ่ม</label>
            </div>
            <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
              {/* <Select className="w-100"  value={ _.find(optionsMonth,(month)=>{ return month.value == routeFrom })  } placeholder={"ค้นหา..."} options={optionsMonth} onChange={(data)=>{ getQueryMonth(data)}} /> */}
              <DatePicker className="antd-datepicker-custom w-100" placeholder="เลือกวันที่" format={"DD/MM/YYYY"} value={(routeFrom) ? moment(routeFrom) : ''} onChange={(r) => { setrouteFrom(r) }} />
            </div>
            <div className="col-4 col-md-2 mt-2">
              <label>ถึง</label>
            </div>
            <div className="col mt-2 date-picker-wrapper">
              {/* <Select className="w-100" placeholder={"ค้นหา..."} value={routeTo} options={optionsYear} onChange={(data)=>{ getQueryYear(data)}}  /> */}
              <DatePicker disabled={(!routeFrom)} disabledDate={d => !d || d.isBefore(routeFrom) || d.isAfter(moment(routeFrom).add(90, 'days'))} className="antd-datepicker-custom w-100" placeholder="เลือกวันที่" format={"DD/MM/YYYY"} value={(routeTo) ? moment(routeTo) : ''} onChange={(r) => { setrouteTo(r) }} />
            </div>
          </div>
          <div className="row">
            <div class="w-100 mt-4 mr-4 d-flex flex-row  justify-content-end">
              <button type="submit" class="btn btn-primary ml-2">Search</button>
              <button onClick={(e) => { clearForm() }} type="button" class="btn btn-danger ml-2">Clear</button>
            </div>
          </div>
        </form>
      </div>
      {
        (new URLSearchParams(props.location.search).get("item_id")) ? <div>
          {
            (dcInventoryLog.length > 0) ? <Chart data={dcInventoryLog} ></Chart> : <div className="text-center"></div>
          }
          {
            (dcInventoryLog.length > 0) ? <DCLogTable data={dcInventoryLog} ></DCLogTable> : <div className="text-center mt-4">ไม่มีข้อมูล</div>
          }
        </div> : <div className="text-center mt-4">กรุณาเลือกสินค้าและช่วงเวลาที่ต้องการ</div>
      }


    </div>
  );
}
