import React from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { PAYMENT_METHODS, API_RESOURCES, FORMAT_APP_DATE } from "../../constants.js";

// Models

// Component
import { OrderFormOtherInfo } from "./OrderFormOtherInfo";
import BankSelect from "../global/BankSelect";
import ErrorMessage from "../global/ErrorMessage";
import ImageUploader from "../global/ImageUploader.js";

export class OrderFormPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { order, disabled } = this.props;

    const paymentInfoForDirectLink = () => {
      if (order.payment_method.method_type === PAYMENT_METHODS.yodl) {
        return (
          <React.Fragment>
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>วันที่แจ้งตั้งหนี้</label>
            </div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <DatePicker
                className="form-control"
                dateFormat={FORMAT_APP_DATE}
                selected={order.payment_method.set_debt_date ? new Date(order.payment_method.set_debt_date) : null}
                onChange={(date) => this.props.onChangePaymentDate(date, "set_debt_date")}
                disabled={disabled}
              />
              <ErrorMessage errorKey={"delivery_date"} errorMessages={this.state.errors} />
            </div>
          </React.Fragment>
        );
      }
    };

    return (
      <React.Fragment>
        {/* Payment Info */}
        <h5 className="pb-1">ข้อมูลทั่วไป</h5>

        <div className="card-box p-3 mt-1">
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>เลขที่ใบสั่งซื้อ</label>
            </div>
            <div className="col-8 col-md-4 mt-2">
              <input className="form-control" id="order-order-code" name="order_code" onChange={this.props.onChangeOrderDetails} value={order.order_code} disabled={disabled}></input>
            </div>
          </div>
        </div>

        <h5 className="mt-4 pb-1">ตัวเลือกการชำระ</h5>

        <div className="card-box p-3 mt-1">
          <div className="row">
            <div className="col">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentMethod"
                  id="paymentMethod1"
                  checked={order.payment_method.method_type === PAYMENT_METHODS.yoda}
                  disabled={disabled}
                  onClick={() => this.props.onSelectPaymentMethod(PAYMENT_METHODS.yoda)}
                ></input>
                <label className="form-check-label" for="paymentMethod1">
                  Direct Approve (YODA)
                </label>
              </div>
            </div>
          </div>
          <div className={`${order.payment_method.method_type === PAYMENT_METHODS.yoda ? "" : "d-none"}`}>
            <div className="row mt-2 bg-ghostwhite">
              <div className="col-4 col-md-2 pr-0 pt-2 text-right">
                <label>ชื่อธนาคาร</label>
              </div>
              <div className="col pt-2">
                <BankSelect onChange={this.props.onSelectBank} bankId={order.payment_method.bank_id} disabled={disabled} />
              </div>
            </div>
            <div className="row pb-2 bg-ghostwhite">
              <div className="col-4 col-md-2 pr-0 pt-2 text-right">
                <label>เบอร์บัญชี</label>
              </div>
              <div className="col pt-2">
                <input className="form-control" type="text" name="bankNumber" disabled={disabled} value={order.payment_method.note} onChange={this.props.onChangePaymentNote}></input>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentMethod"
                  id="paymentMethod2"
                  checked={order.payment_method.method_type === PAYMENT_METHODS.yodl}
                  disabled={disabled}
                  onClick={() => this.props.onSelectPaymentMethod(PAYMENT_METHODS.yodl)}
                ></input>
                <label className="form-check-label" for="paymentMethod2">
                  Direct Link (YODL)
                </label>
              </div>
            </div>
          </div>
          <div className={`${order.payment_method.method_type === PAYMENT_METHODS.yodl ? "" : "d-none"}`}>
            <div className="row pb-2 bg-ghostwhite">
              <div className="col-4 col-md-2 pr-0 pt-2 text-right">
                <label>ระบุ</label>
              </div>
              <div className="col pt-2">
                <input className="form-control" type="text" name="bankNumber" disabled={disabled} value={order.payment_method.note} onChange={this.props.onChangePaymentNote}></input>
              </div>
            </div>
          </div>
        </div>
        <div className="card-box p-3 mt-1 mb-1">
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>วันที่ชำระ</label>
            </div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <DatePicker
                className="form-control"
                dateFormat={FORMAT_APP_DATE}
                selected={order.payment_method.payment_date ? new Date(order.payment_method.payment_date) : null}
                onChange={(date) => this.props.onChangePaymentDate(date, "payment_date")}
                disabled={disabled}
              />
            </div>
            {paymentInfoForDirectLink()}
          </div>
          {order.payment_method.method_type === PAYMENT_METHODS.yodl ? (
            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label>หลักฐานการจ่ายเงิน</label>
              </div>
              <div className="col-8 col-md-4 date-picker-wrapper mt-2">
                <ImageUploader
                  file_category={`purchasing_orders/${order.id}/payslip`}
                  url={order.payment_method.s3_slip_url}
                  onUploadNewFile={this.props.onUploadPaySlip}
                  onDeleteFile={this.props.onDeletePaySlip}
                  disabled={disabled}
                  withUniqueKey={false}
                  key={this.state.image_uploader_key}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* Other Info */}
        <OrderFormOtherInfo page_nb={this.props.page_nb} note={this.props.order.order_notes.filter((elm) => elm.page_nb === this.props.page_nb)[0]} onChangeNote={this.props.onChangeNote} disabled={this.props.disabled} key={this.props.resetKeyNote} />
      </React.Fragment>
    );
  }
}
