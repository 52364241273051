export const APP_NAME = "PRODUCT4SALES";
export const APP_TITLE_QUICK_SEARCH = "ค้นหาด่วน";
export const APP_TITLE_QUO = "ใบเสนอราคา";
export const APP_TITLE_PO = "ใบสั่งซื้อสินค้า";
export const APP_TITLE_MR = "ใบสั่งจ้างผลิต";
export const APP_TITLE_DC_INBOUND_ORDER = "ออร์เดอร์เข้า DC";
export const APP_TITLE_DC_INVENTORIES_MANAGEMENT = "จัดการคลังสินค้า DC";
export const APP_TITLE_DC_INVENTORIES_DEATIL = "รายละเอียดสินค้า";
export const APP_TITLE_DC_INVENTORIES_LOG = "Inventory Log";
export const APP_TITLE_VENDOR_BILLING = "ติดตามการวางบิล";
export const APP_TITLE_ORDER = "ออร์เดอร์";
export const APP_TITLE_ADMIN = "หน้าผู้ดูแลระบบ";
export const APP_TITLE_PROFILE = "โปรไฟล์";
export const APP_TITLE_REPORT_TR_ORDER = "ติดตามออร์เดอร์";
export const APP_TITLE_REPORT_STATION = "รายงานข้อมูลของสถานี";
export const APP_TITLE_REPORT_VENDOR = "รายงานแจ้งสั่งผลิต";
export const APP_TITLE_REPORT_CONTRACT_BUDGET = "รายงานวงเงินสัญญา";
export const APP_TITLE_REPORT_INVENTORY = "รายงานคลังสินค้า DC";
export const APP_TRACKING_PORTAL_ACCESS = "เข้าสู่ระบบ Tracking";
export const R_SCREEN_DOCK_SIZE = 1300;
export const R_SCREEN_DOCK_SIZE_SM = 900;
export const R_SCREEN_WIDTH = {
  md: 767,
};
export const R_SEL_OPTION_MAX_H = 200;

export const FORMAT_DATE = "YYYY-MM-DD";
export const FORMAT_EXPORT_DATE = "DD.MM.YYYY";
export const FORMAT_APP_DATE = "dd/MM/yyyy";
export const FORMAT_DATE_EXCEL_IN = "DD.MM.YYYY";
export const PER_PAGE = 15;

export const API_RESOURCES = {
  quotation: "quotations",
  po: "purchasing_orders",
  mr: "manufacture_requests",
  order: "orders",
  dc_inbound_order: "dc_inbound_orders",
  dc_inventories_management: "dc_inventories_orders",
  order_po_gr: "order_po_gr",
  admin: "admin",
  database: "database",
  report: "reports",
  profile: "profile",
  vendor: "vendors",
  contract: "contracts",
  tracking_portal: "tracking",
};

export const PATH_VENDORS = {
  billing: "billing",
};

export const PAGES = {
  home: {
    value: 0,
    name: "home",
  },
  quotation: {
    value: 1,
    name: "quotation",
  },
  data_center: {
    value: 2,
    name: "data_center",
  },
};

export const OPTIONS_PERIOD = {
  month: {
    value: 1,
    name: "Monthly",
  },
  quarter: {
    value: 2,
    name: "Quarterly",
  },
  year: {
    value: 3,
    name: "YTD",
  },
};

export const QUO_STATES = {
  draft: {
    name: "draft",
    value: 1,
  },
  submitted: {
    name: "submitted",
    value: 2,
  },
  cancelled: {
    name: "cancelled",
    value: 3,
  },
  approved: {
    name: "approved",
    value: 10,
  },
  rejected: {
    name: "rejected",
    value: 4,
  },
  // po_issued_partially: {
  //   name: "PO issued partially",
  //   value: 11
  // },
  // po_issued_completed: {
  //   name: "PO issued completed",
  //   value: 20
  // },
  order_issued_partially: {
    name: "Order issued partially",
    value: 12,
  },
  order_issued_completed: {
    name: "Order issued completed",
    value: 21,
  },
  cloned_to_edit: {
    name: "cloned to edit",
    value: 5,
  },
  suspended: {
    name: "suspended",
    value: 6,
  },
};

export const PO_STATES = {
  draft: {
    name: "draft",
  },
  submitted: {
    name: "submitted",
  },
  cancelled: {
    name: "cancelled",
  },
};

export const INBOUND_STATES = {
  1: {
    name: "Draft",
  },
  2: {
    name: "Pending delivery",
  },
  3: {
    name: "Partially received",
  },
  4: {
    name: "Received",
  },
};

export const MR_STATES = {
  draft: {
    name: "draft",
  },
  submitted: {
    name: "submitted",
  },
  cancelled: {
    name: "cancelled",
  },
};

export const DC_INBOUND_ORDER_PROCESS = {
  xxxxx: {
    name: "xxxxx",
    value: 1,
  },
  yyyyy: {
    name: "yyyyy",
    value: 2,
  },
  zzzzz: {
    name: "zzzzz",
    value: 3,
  },
};

export const DC_INBOUND_ORDER_STATES = {
  in_progress: {
    name: "in progress",
  },
  completed: {
    name: "completed",
  },
  draft: {
    name: "draft",
    value: 1,
  },
  submitted: {
    name: "submitted",
    value: 2,
  },
  cancelled: {
    name: "cancelled",
    value: 3,
  },
  approved: {
    name: "approved",
    value: 10,
  },
  rejected: {
    name: "rejected",
    value: 4,
  },
  order_issued_partially: {
    name: "Order issued partially",
    value: 12,
  },
  order_issued_completed: {
    name: "Order issued completed",
    value: 21,
  },
  cloned_to_edit: {
    name: "cloned to edit",
    value: 5,
  },
  suspended: {
    name: "suspended",
    value: 6,
  },
};

export const ORDER_STATES = {
  in_progress: {
    name: "in progress",
  },
  completed: {
    name: "completed",
  },
  cancelled: {
    name: "cancelled",
  },
};

export const INBOUND_ORDER_STATES = {
  draft: "Draft",
  pending_delivery: "Pending Delivery",
  partially_received: "Partially Received",
  received: "Received",
  cancelled: "Cancelled",
};

export const ORDER_TYPE = {
  vendor: {
    name: "Vendor",
  },
  dc: {
    name: "DC",
  },
};

export const PAYMENT_METHODS = {
  yoda: "yoda",
  yodl: "yodl",
};

export const DATA_SUB_PAGES = {
  users: "users",
  categories: "categories",
  items: "items",
  templates: "templates",
};

export const MODAL_STYLE_LARGE = {
  content: {
    left: "50%",
    top: "50%",
    width: "90%",
    height: "90%",
    overflow: "hidden",
    transform: "translate(-50%, -50%)",
    // display: "grid",
    // gridTemplateRows: "100%",
  },
  overlay: {
    zIndex: 299,
  },
};

export const MODAL_STYLE_LARGE_ORDER = {
  content: {
    left: "50%",
    top: "50%",
    width: "90%",
    height: "90%",
    overflow: "hidden",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 299,
  },
};

export const MODAL_ITEM_FEE_Z_999 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "80%",
    overflow: "hidden",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 999,
  },
};

export const MODAL_STYLE_LARGE_Z_999 = {
  content: {
    left: "50%",
    top: "50%",
    width: "90%",
    height: "90%",
    overflow: "hidden",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 999,
  },
};

export const MODAL_STYLE_LARGE_MD = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "80%",
    overflow: "hidden",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 10,
  },
};

export const MODAL_STYLE_SMALL = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "45%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 10,
  },
};

export const MODAL_STYLE_SMALL_SM = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "80%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 10,
  },
};

export const MODAL_SELECT_STYLE = {
  menuPortal: (base) => ({ ...base, zIndex: 11 }),
};

export const CLONE_OPTIONS = {
  clone_to_new: 1,
  clone_to_edit: 2,
};

export const TRACKING_USER_TYPE = {
  buyers: {
    name: "buyer",
    value: 0,
  },
  client_stations: {
    name: "client_station",
    value: 0,
  },
  vendor: {
    name: "vendor",
    value: 1,
  },
  admin: {
    name: "admin",
    value: 2,
  },
};
