import React from 'react';
import { DocumentPreview } from '../global/DocumentPreview.js'

export class MrFormDocuments extends React.Component {
  render() {
    let url = (this.props.mr===null) ? '' : this.props.mr.s3_base_url
    return (
      <React.Fragment>
        <DocumentPreview
          docName="เอกสาร MR"
          url={url}
        />
      </React.Fragment>
    );
  }
}