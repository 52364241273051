import React from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import Select from "react-select";
import { Space, Radio } from "antd";
import { Link } from "react-router-dom";

import { requiredFields, validateMR, isDisabled, vendorBillingFields } from "../../models/ManufactureRequest";
import { MrFormDocuments } from "./MrFormDocuments";
import { MrFormStations } from "./MrFormStations";
import { MrFormStamp } from "./MrFormStamp";

import { MR_STATES, API_RESOURCES, APP_TITLE_MR, FORMAT_APP_DATE } from "../../constants.js";

// components
import ImageUploader from "../global/ImageUploader.js";
import Spinner from "../global/Spinner";
import ErrorMessage from "../global/ErrorMessage";
import ModalConfirmation from "../global/ModalConfirmation";
import Number from "../global/Number";
import VendorSelect from "../global/VendorSelect";
import ContractSelect from "../global/ContractSelect";
import OrderSelect from "../global/OrderSelect";
import InboundOrderSelect from "../global/InboundOrderSelect";

// models
import { itemCategoryName } from "../../models/ItemCategories";
import { getNewMR, NEW_ORDER_PO_GR, NEW_INBOUND_ORDER_PO, MR_STATION_TYPE_DEFAULT } from "../../models/ManufactureRequest";

// functions
import { generateID, uniqueKey } from "../../functions/Random.js";
import { objectIsEmpty, objectIndexOf } from "../../functions/Object";
import { formatThaiDate, formatApiDate } from "../../functions/Date";

const MR_TABS = {
  info: 0,
  stations: 1,
  documents: 2,
  stamp: 3,
};

const ItemTemplate = {
  id: "",
  name: "",
  type: "",
  category: "",
};

export class MrForm extends React.Component {
  constructor(props) {
    super(props);

    let mr_id = props.id && props.id !== "new" ? parseInt(props.id) : null;

    this.state = {
      current_tab: MR_TABS.info,
      selected_mr_id: mr_id,
      selected_po_gr: null,
      po_gr_options: [],
      request: getNewMR(props.current_user),
      item_category_options: [],
      disabled: false,
      is_soft_linked: false,
      is_linked: false,
      is_unlinking: false,
      is_loading: true,
      is_canceling: false,
      is_leaving: false,
      is_mount: false,
      is_update: false,
      randomKey: uniqueKey(),
      errors: {},
      image_uploader_key: null,
      inspection_content: "",
      order_type: null,
    };

    this.onChangeFormTab = this.onChangeFormTab.bind(this);
    this.onChangeMrInfo = this.onChangeMrInfo.bind(this);
    this.onUploadNewSignature = this.onUploadNewSignature.bind(this);
    this.onUploadNewInspectionSignature = this.onUploadNewInspectionSignature.bind(this);
    this.onAddStationList = this.onAddStationList.bind(this);
    this.onDeleteStationList = this.onDeleteStationList.bind(this);
    this.onChangeStationList = this.onChangeStationList.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeVendor = this.onChangeVendor.bind(this);
    this.onChangeContract = this.onChangeContract.bind(this);

    // Cancel
    this.onHandleCancelModal = this.onHandleCancelModal.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onCancelMR = this.onCancelMR.bind(this);

    // submit
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.onSaveMR = this.onSaveMR.bind(this);

    // back
    this.backToMRIndex = this.backToMRIndex.bind(this);
    this.onHandleModalBack = this.onHandleModalBack.bind(this);
    this.onClickBack = this.onClickBack.bind(this);

    // Stamp
    this.onChangeResponsiblePerson = this.onChangeResponsiblePerson.bind(this);
    this.onClickResetCommittee = this.onClickResetCommittee.bind(this);
    this.onToggleCommittee = this.onToggleCommittee.bind(this);
  }

  componentDidMount() {
    if (this.state.selected_mr_id !== null) {
      this.getManufactureRequest();
    }

    if (this.state.disabled === false) {
      // this.getItemCategories()
      this.getTemplate();
    }
  }

  setEmptyCommittee() {
    let num_of_committee = 4;
    let committee = [];
    for (let i = 0; i < num_of_committee; i++) {
      committee.push({
        id: generateID(),
        position: i + 1,
        name: "",
        title: "",
        code: "",
      });
    }
    return committee;
  }

  getManufactureRequest() {
    let order_type = null;
    axios
      .get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.mr}/${this.state.selected_mr_id}`)
      .then((response) => {
        let { is_linked } = this.state;
        let request = response.data;

        if (request.contract_id) {
          this.getContractItemCategories(request.contract_id);
        }

        if (request.manufacture_request_stations) {
          request.manufacture_request_stations.map((station) => {
            if (station.manufacture_request_station_committees.length === 0) {
              station.manufacture_request_station_committees = this.setEmptyCommittee();
            }
          });
        }

        // check if this mr is linked to order (order or inbound_order)
        if (request.order_po_gr) {
          is_linked = request.order_po_gr.id;
          order_type = "vendor";
        } else if (request.inbound_order_item_po) {
          is_linked = request.inbound_order_item_po.id;
          order_type = "dc";
        } else {
          request.order_po_gr = NEW_ORDER_PO_GR;
          request.inbound_order_item_po = NEW_INBOUND_ORDER_PO;
        }

        this.setState({
          request: { ...request },
          disabled: isDisabled(response.data.state),
          is_loading: false,
          is_mount: true,
          is_linked: is_linked,
          randomKey: uniqueKey(),
          image_uploader_key: uniqueKey(),
          order_type: order_type,
        });

        console.log("request>>>>", request);
        console.log("this.state>>>>", this.state);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          is_loading: false,
        });
      });
  }

  // getItemCategories() {
  //   let params = {
  //     filter: {is_active: true}
  //   }
  //   axios.get(`${process.env.REACT_APP_API_URL}/item_categories`, {
  //     params: params
  //   })
  //   .then((response) => {
  //     let item_category_options = []

  //     response.data.map(cate => {
  //       let name = itemCategoryName(cate)
  //       item_category_options.push({
  //         id: cate.id,
  //         name: name,
  //         value: name,
  //         label: name,
  //       })
  //     })
  //     this.setState({
  //       item_category_options: item_category_options
  //     })
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   })
  // }

  getContractItemCategories(contract_id) {
    let params = {
      filter: { contract_id: contract_id },
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/contract_item_categories`, {
        params: params,
      })
      .then((response) => {
        let item_category_options = [];

        response.data.map((contract_cate) => {
          if (contract_cate.item_category) {
            let name = itemCategoryName(contract_cate.item_category);
            item_category_options.push({
              id: contract_cate.item_category_id,
              name: name,
              value: name,
              label: name,
            });
          }
        });
        this.setState({
          item_category_options: item_category_options,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onToggleCommittee(e) {
    console.log("onToggleCommittee :>> ", e);
    let { request } = this.state;

    request.toggle_committee = e;

    this.setState({ request });
  }

  getCommittee(current_station) {
    let params = {
      filter: {
        contract_id: this.state.request.contract_id,
        client_station_id: current_station.client_station_id,
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/contract_committee`, {
        params: params,
      })
      .then((response) => {
        if (response.data.length > 0) {
          let { request } = this.state;
          if (response.data[0].name) {
            let manufacture_request_station = request.manufacture_request_stations.filter((station) => station.id === current_station.id)[0];
            manufacture_request_station.manufacture_request_station_committees = [];

            response.data.map((elm) => {
              manufacture_request_station.manufacture_request_station_committees.push({
                id: generateID(),
                position: elm.position,
                name: elm.name,
                code: elm.code,
                title: elm.title,
              });
            });

            this.setState({
              request: { ...request },
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getTemplate() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/templates/manufacture_request`)
      .then((response) => {
        let { request, selected_mr_id } = this.state;
        let template = response.data;
        if (selected_mr_id === null) {
          request.approver_name = template.mr_approver_name;
          request.approver_title = template.mr_approver_title;
          request.mr_subject = template.mr_title_name;
          request.s3_signature_upload = template.mr_signature_key;
          request.s3_inspection_sign_url = template.mr_inspection_s3_sign_url_key;
        }
        let inspection_content = template.mr_inspection_content;
        this.setState({
          request: { ...request },
          image_uploader_key: uniqueKey(),
          inspection_content: inspection_content,
          is_loading: false,
          is_mount: true,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  postNewMR(newState) {
    let { request, is_leaving } = this.state;
    if (newState !== undefined) {
      request.state = newState;
    }
    if (this.props.current_user) {
      request.user_id = this.props.current_user.id;
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.mr}`, {
        mr: this.state.request,
      })
      .then((response) => {
        window.location.href = `/${API_RESOURCES.mr}/${response.data.id}`;
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_loading: false });
      });
    this.setState({ is_loading: true });
  }

  putMR(newState) {
    let { request, is_leaving } = this.state;
    if (newState !== undefined) {
      request.state = newState;
    }
    if (this.props.current_user) {
      request.user_id = this.props.current_user.id;
    }

    axios
      .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.mr}/${request.id}`, {
        mr: this.state.request,
      })
      .then((response) => {
        if (is_leaving) {
          window.location.href = `/${API_RESOURCES.mr}`;
        } else {
          this.updateFromAfterSave(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_loading: false });
      });
    this.setState({ is_loading: true });
  }

  onCancelMR(state) {
    let { request } = this.state;
    request.state = state;
    axios
      .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.mr}/${request.id}/cancel`, {
        state: request.state,
        cancel_note: request.cancel_note,
      })
      .then((response) => {
        request.order_po_gr = NEW_ORDER_PO_GR;
        request.inbound_order_item_po = NEW_INBOUND_ORDER_PO;
        this.setState({ request: { ...request }, is_loading: false, is_updated: false, is_canceling: false, disabled: true, order_type: null });
        alert("saved!");
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_loading: false });
      });

    this.setState({ is_loading: true });
  }

  updateFromAfterSave(request) {
    let order_type = null;
    // check if this mr is linked to order
    let { is_linked } = this.state;
    if (request.order_po_gr) {
      is_linked = request.order_po_gr.id;
      order_type = "vendor";
    } else if (request.inbound_order_item_po) {
      is_linked = request.inbound_order_item_po.id;
      order_type = "dc";
    } else {
      request.order_po_gr = NEW_ORDER_PO_GR;
      request.inbound_order_item_po = NEW_INBOUND_ORDER_PO;
    }
    this.setState({
      request: { ...request },
      is_loading: false,
      is_updated: false,
      randomKey: uniqueKey(),
      disabled: isDisabled(request.state),
      is_linked: is_linked,
      order_type,
    });
    alert("saved!");
  }

  onHandleSubmit(e, newState) {
    let { request } = this.state;
    let errors = validateMR(request);
    if (objectIsEmpty(errors)) {
      if (request.id !== null) {
        this.putMR(newState);
      } else {
        this.postNewMR(newState);
      }
    } else {
      alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      this.setState({ errors: errors });
    }
  }

  onSaveMR() {
    let { request } = this.state;
    validateMR(request);
  }

  onChangeFormTab(tab) {
    this.setState({ current_tab: tab });
  }

  onAddStationList(station, category, type) {
    let { request } = this.state;
    let new_option = {
      id: generateID(),
      client_station_id: station.id,
      station_name: station.name,
      item_category_id: category.id,
      item_category_name: category.value,
      station_type: type,
      manufacture_request_station_committees: this.setEmptyCommittee(),
    };
    request.manufacture_request_stations.push(new_option);

    // get committee
    this.getCommittee(new_option);
    this.setState({ request: request, is_update: true });
  }

  onDeleteStationList(id) {
    let { request } = this.state;
    request.manufacture_request_stations = request.manufacture_request_stations.filter((elm) => elm.id !== id);
    this.setState({ request: request, is_update: true });
  }

  onChangeStationList(e, id) {
    let stations = this.state.request.manufacture_request_stations;
    let de_item_index = stations.findIndex((x) => x.id === id);
    stations[de_item_index] = {
      ...stations[de_item_index],
      [e.target.name]: e.target.value,
    };
    this.setState({ stations: stations, is_update: true });
  }

  onChangeDate(field, date) {
    let request = this.state.request;

    // for fixing date problem, (input 2/8/64 -> save -> return 1/8/64)
    // https://trello.com/c/qWzFkFZu
    // if the default is not given to Datepicker, the time will be 00:00:00
    // if new Date() is given as a defualt, the time will be xx:xx:xx
    if (date instanceof Date) {
      date = formatApiDate(date);
    }
    request = {
      ...request,
      [field]: date,
    };
    this.setState({ request: request, is_update: true });
  }

  onChangeVendor(id, e) {
    let { request, is_soft_linked } = this.state;
    if (id) {
      request.vendor_id = id;
      request.manufacturer_name = e.text;
      if (!is_soft_linked) {
        request.contract_id = null;
        request.contract_no = "";
        request.contract_date = null;
        request.nb_days_to_complete = null;
      }
      this.setState({ request: request, is_update: true });
    }
  }

  onChangeContract(id, e) {
    let request = this.state.request;
    request.contract_id = id;
    request.contract_no = e.code;
    request.contract_date = e.from_date;
    request.nb_days_to_complete = e.nb_days_to_complete;

    // get contract's item categories
    this.getContractItemCategories(id);

    this.setState({ request: { ...request }, is_update: true });
  }

  onChangeMrInfo(e) {
    let request = this.state.request;
    request = {
      ...request,
      [e.target.name]: e.target.value,
    };
    this.setState({ request: request, is_update: true });
  }

  onUploadNewSignature(key) {
    let request = this.state.request;
    request.s3_signature_upload = key;
    this.setState({ request: request, is_update: true });
  }

  onUploadNewInspectionSignature(key) {
    let request = this.state.request;
    request.s3_inspection_sign_url = key;
    this.setState({ request: request, is_update: true });
  }

  /* ========================================================= */
  /* Cancel (start) */
  onHandleCancelModal(value) {
    this.setState({ is_canceling: value });
  }
  onCancel() {
    if (this.state.request.cancel_note) {
      this.onCancelMR(MR_STATES.cancelled.name);
    } else {
      alert("โปรดระบุเหตุผล");
    }
  }

  cancelMR() {
    let { disabled, request } = this.state;
    let disable_cancel_modal = request.state === MR_STATES.cancelled.name;
    if (this.state.is_canceling) {
      const content = (cancel_note) => (
        <React.Fragment>
          <div className="row pb-2 mb-2">
            <div className="col-12 col-md-4 pt-md-2">
              <label>เหตุผล</label>
            </div>
            <div className="col pt-2">
              <textarea className="form-control" type="text" name="cancel_note" defaultValue={this.state.disabled ? cancel_note : ""} onChange={this.onChangeMrInfo} rows="4" disabled={disable_cancel_modal}></textarea>
            </div>
          </div>
        </React.Fragment>
      );
      return <ModalConfirmation isOpen={true} title="ยกเลิกใบแจ้งผลิต" content={content(request.cancel_note)} onConfirm={disable_cancel_modal ? undefined : this.onCancel} onCancel={() => this.onHandleCancelModal(false)} />;
    }
  }
  /* Cancel (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Stamp (start) */
  onChangeResponsiblePerson(e, station_id, id) {
    let { request } = this.state;
    let station = request.manufacture_request_stations.filter((elm) => elm.id === station_id)[0];
    let person_index = objectIndexOf(station.manufacture_request_station_committees, "id", id);

    station.manufacture_request_station_committees[person_index] = {
      ...station.manufacture_request_station_committees[person_index],
      [e.target.name]: e.target.value,
    };

    this.setState({ request: request });
  }

  onClickResetCommittee(e, station) {
    this.getCommittee(station);
  }
  /* Stamp (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Order (start) */

  onSelectOrderType = (e) => {
    let { errors } = this.state;

    if (errors["order_id"]) {
      delete errors["order_id"];
    }
    if (this.state.errors["inbound_order_id"]) {
      delete errors["inbound_order_id"];
    }

    this.setState({
      order_type: e.target.value,
      errors: errors,
      selected_po_gr: null,
    });
  };

  onSelectInboundOrder = (value, e) => {
    let { request, errors } = this.state;
    console.log("request.inbound_order_item_po :>", request.inbound_order_item_po);
    let newRequest = request;
    newRequest.inbound_order_item_po.inbound_order_id = value;
    newRequest.vendor_id = null;
    newRequest.manufacturer_name = "";
    newRequest.contract_id = null;
    newRequest.contract_no = "";
    newRequest.contract_date = null;
    newRequest.manufacture_request_stations = [];
    let po_gr_options = [];
    if (errors["inbound_order_id"]) {
      delete errors["inbound_order_id"];
    }
    if (errors["order_id"]) {
      delete errors["order_id"];
    }
    if (e) {
      if (e.order.inbound_order_item_pos && e.order.inbound_order_item_pos.length > 0) {
        for (let elm of e.order.inbound_order_item_pos) {
          let option = elm;
          let item_cate = e.order.order_item_categories.filter((cate) => cate.contract_id === elm.contract_id)[0];
          option.value = elm.id;
          option.item_category = { id: item_cate.item_category_id, value: itemCategoryName(item_cate) };
          option.label = `[${elm.contract.code}] ${elm.contract.name} - ${elm.contract.vendor.name}`;
          option.disabled = elm.manufacture_request_id ? true : false;
          po_gr_options.push(option);
        }
      } else {
        errors["order_id"] = "ไม่มีสัญญาที่สามารถออกใบสั่งจ้างผลิตได้ โปรดตรวจสอบในออร์เดอร์หน้า PO";
      }
    }
    this.setState({
      request: { ...newRequest },
      po_gr_options: po_gr_options,
      selected_po_gr: null,
      is_soft_linked: value ? true : false,
      errors: errors,
    });
  };
  onSelectInboundOrderPoGr = (e) => {
    let { request } = this.state;
    if (e) {
      console.log(e);
      request.inbound_order_item_po.id = e.id;
      request.inbound_order_item_po.po_code = e.po_code;
      request.inbound_order_item_po.po_date = e.po_date;
      request.contract_id = e.contract_id;
      request.contract_no = e.contract.code;
      request.contract_date = e.contract.from_date;
      request.nb_days_to_complete = e.contract.nb_days_to_complete;
      request.vendor_id = e.contract.vendor.id;
      request.manufacturer_name = e.contract.vendor.name;
      this.onAddStationList({ name: "คลัง DC", id: 637 }, e.item_category, MR_STATION_TYPE_DEFAULT);
      this.setState({ request: { ...request }, selected_po_gr: e });
    }
  };

  onSelectOrder = (value, e) => {
    let { request, errors } = this.state;
    request.order_po_gr.order_id = value;
    request.vendor_id = null;
    request.manufacturer_name = "";
    request.contract_id = null;
    request.contract_no = "";
    request.contract_date = null;
    request.manufacture_request_stations = [];
    let po_gr_options = [];
    if (errors["order_id"]) {
      delete errors["order_id"];
    }
    if (errors["inbound_order_id"]) {
      delete errors["inbound_order_id"];
    }
    if (e) {
      if (e.order.order_po_grs && e.order.order_po_grs.length > 0) {
        for (let elm of e.order.order_po_grs) {
          let option = elm;
          let item_cate = e.order.order_item_categories.filter((cate) => cate.contract_id === elm.contract_id)[0];
          option.value = elm.id;
          option.quotation = e.order.quotation;
          option.item_category = { id: item_cate.item_category_id, value: itemCategoryName(item_cate) };
          option.label = `[${elm.contract.code}] ${elm.contract.name} - ${elm.contract.vendor.name}`;
          option.disabled = elm.manufacture_request_id ? true : false;
          po_gr_options.push(option);
        }
      } else {
        errors["order_id"] = "ไม่มีสัญญาที่สามารถออกใบสั่งจ้างผลิตได้ โปรดตรวจสอบในออร์เดอร์หน้า PO/GR";
      }
    }
    this.setState({
      request: { ...request },
      po_gr_options: po_gr_options,
      selected_po_gr: null,
      is_soft_linked: value ? true : false,
      errors: errors,
    });
  };
  onSelectOrderPoGr = (e) => {
    let { request } = this.state;
    if (e) {
      console.log(e);
      request.order_po_gr.id = e.id;
      request.order_po_gr.po_code = e.po_code;
      request.order_po_gr.po_date = e.po_date;
      request.contract_id = e.contract_id;
      request.contract_no = e.contract.code;
      request.contract_date = e.contract.from_date;
      request.nb_days_to_complete = e.contract.nb_days_to_complete;
      request.vendor_id = e.contract.vendor.id;
      request.manufacturer_name = e.contract.vendor.name;
      this.onAddStationList(e.quotation.client_station, e.item_category, MR_STATION_TYPE_DEFAULT);
      this.setState({ request: { ...request }, selected_po_gr: e });
    }
  };
  onConfirmUnlinkOrder = () => {
    let { request, order_type } = this.state;
    let params = {};

    if ((request.order_po_gr && request.order_po_gr.id) || (request.inbound_order_item_po && request.inbound_order_item_po.id)) {
      params = {
        id: order_type == "dc" ? request.inbound_order_item_po.id : request.order_po_gr.id,
        mr: {
          inbound_order_item_po: request.inbound_order_item_po,
        },
      };
      console.log("params>>>>>", params);
      this.setState({ is_loading: true });
      axios
        .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.mr}/unlink_order`, {
          ...params,
        })
        .then((response) => {
          request.order_po_gr = NEW_ORDER_PO_GR;
          request.inbound_order_item_po = NEW_INBOUND_ORDER_PO;
          this.setState({
            is_loading: false,
            is_linked: false,
            is_unlinking: false,
            request: request,
            order_type: null,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ is_loading: false });
        });
    }
  };
  display_confirm_unlink() {
    let { is_unlinking } = this.state;
    if (is_unlinking) {
      return (
        <ModalConfirmation
          isOpen={true}
          title="ยกเลิกการเชื่อมต่อกับออร์เดอร์"
          content=""
          onConfirm={this.onConfirmUnlinkOrder}
          onCancel={() => {
            this.setState({ is_unlinking: false });
          }}
        />
      );
    }
  }
  /* Order (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Back (start) */
  backToMRIndex() {
    this.props.history.push({ pathname: `/${API_RESOURCES.mr}`, search: this.props.location.search });
  }
  onHandleModalBack(value) {
    this.setState({ is_leaving: value });
  }
  onClickBack() {
    if (this.state.is_updated) {
      this.onHandleModalBack(true);
    } else {
      this.backToMRIndex();
    }
  }
  displayBackConfirmation() {
    if (this.state.is_leaving) {
      return (
        <ModalConfirmation
          isOpen={true}
          title="บันทึกใบแจ้งผลิต ?"
          content="มีการแก้ไขที่ยังไม่ได้ถูกบันทึก ต้องการบันทึก?"
          btnDanger="ไม่บันทึก"
          onConfirm={this.onHandleSubmit}
          onCustomAction={this.backToMRIndex}
          onCancel={() => this.onHandleModalBack(false)}
        />
      );
    }
  }
  /* Back (end) */
  /* ========================================================= */

  inputField(elm, field, text_align, type, disabled) {
    if (type === "Number") {
      return <Number innerClass={text_align} name={field} value={elm[field]} onChange={this.onChangeMrInfo} disabled={disabled} />;
    } else if (type === "Text") {
      return <textarea className="form-control" name={field} rows={3} onChange={this.onChangeMrInfo} value={elm[field]} disabled={disabled}></textarea>;
    } else {
      return <input className={`form-control ${text_align}`} name={field} value={elm[field]} onChange={this.onChangeMrInfo} disabled={disabled}></input>;
    }
  }

  actionBtns() {
    const SaveBtn = () => (
      <button type="button" className="btn btn-primary btn-sm ml-2" onClick={this.onHandleSubmit}>
        Save
      </button>
    );
    const SubmitBtn = () => {
      if (this.state.selected_mr_id) {
        return (
          <button type="button" className="btn btn-primary btn-sm ml-2" onClick={(e) => this.onHandleSubmit(e, MR_STATES.submitted.name)}>
            Submit
          </button>
        );
      } else {
        return null;
      }
    };
    const CancelBtn = () => {
      if (this.state.selected_mr_id) {
        return (
          <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => this.onHandleCancelModal(true)}>
            Cancel
          </button>
        );
      } else {
        return null;
      }
    };
    const CanceledBtn = () => (
      <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => this.onHandleCancelModal(true)}>
        เหตุผล
      </button>
    );

    switch (this.state.request.state) {
      case MR_STATES.draft.name:
        return (
          <React.Fragment>
            <SaveBtn />
            <SubmitBtn />
            <CancelBtn />
          </React.Fragment>
        );
      case MR_STATES.submitted.name:
        return (
          <React.Fragment>
            <SaveBtn />
          </React.Fragment>
        );
      case MR_STATES.cancelled.name:
        return (
          <React.Fragment>
            <CanceledBtn />
          </React.Fragment>
        );
      default:
    }
  }

  displaySelectOrder() {
    let { request, disabled, is_soft_linked, is_linked, po_gr_options, selected_po_gr } = this.state;
    let { order_po_gr, inbound_order_item_po } = request;
    console.log("inbound_order_item_po", inbound_order_item_po);
    console.log(order_po_gr);
    if (this.state.order_type == "dc") {
      return <InboundOrderSelect onChange={this.onSelectInboundOrder} orderId={inbound_order_item_po && inbound_order_item_po.inbound_order_id} key={inbound_order_item_po && inbound_order_item_po.inbound_order_id} disabled={disabled || is_linked} />;
    } else if (this.state.order_type == "vendor") {
      return <OrderSelect onChange={this.onSelectOrder} orderId={order_po_gr.order_id} key={order_po_gr.order_id} disabled={disabled || is_linked} />;
    } else {
      return "";
    }
  }

  displaySelectPO() {
    let { request, disabled, is_soft_linked, is_linked, po_gr_options, selected_po_gr } = this.state;
    let { order_po_gr, inbound_order_item_po } = request;

    if (this.state.order_type == "dc") {
      if (!(inbound_order_item_po && inbound_order_item_po.inbound_order_id && (disabled || is_linked))) {
        return (
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>ผู้ผลิต - สัญญา</label>
            </div>
            <div className="col mt-2">
              <Select
                className="select2-style"
                onChange={this.onSelectInboundOrderPoGr}
                value={selected_po_gr}
                options={po_gr_options}
                isDisabled={disabled || is_linked}
                isOptionDisabled={(option) => option.disabled}
                placeholder={"เลือกผู้ผลิต - สัญญา"}
              />
            </div>
          </div>
        );
      }
    } else if (this.state.order_type == "vendor") {
      if (!(order_po_gr.order_id && (disabled || is_linked))) {
        return (
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>ผู้ผลิต - สัญญา</label>
            </div>
            <div className="col mt-2">
              <Select className="select2-style" onChange={this.onSelectOrderPoGr} value={selected_po_gr} options={po_gr_options} isDisabled={disabled || is_linked} isOptionDisabled={(option) => option.disabled} placeholder={"เลือกผู้ผลิต - สัญญา"} />
            </div>
          </div>
        );
      }
    } else {
      return "";
    }
  }

  displayLinkedOrder() {
    let { request, order_type } = this.state;
    let { order_po_gr, inbound_order_item_po } = request;

    if (order_type == "vendor") {
      if (order_po_gr.order_id) {
        return (
          <Link to={`/${API_RESOURCES.order}/${order_po_gr.order_id}`} target="_blank" rel="noopener noreferrer">
            คลิกเพื่อเปิดดูออร์เดอร์
          </Link>
        );
      } else {
        return "-";
      }
    } else if (order_type == "dc") {
      if (inbound_order_item_po && inbound_order_item_po.inbound_order_id) {
        return (
          <Link to={`/${API_RESOURCES.dc_inbound_order}/${inbound_order_item_po.inbound_order_id}`} target="_blank" rel="noopener noreferrer">
            คลิกเพื่อเปิดดูออร์เดอร์
          </Link>
        );
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  }

  displayCustomerPage() {
    let { request, disabled, is_soft_linked, is_linked, po_gr_options, selected_po_gr, order_type } = this.state;
    let { order_po_gr, inbound_order_item_po } = request;
    return (
      <React.Fragment>
        <div className={this.state.current_tab === MR_TABS.info ? "col-12 offset-lg-1 col-lg-10 px-3 pb-3 block-info" : "d-none"}>
          <div className="d-flex justify-content-between">
            <h5 className="pb-1">ข้อมูลออร์เดอร์</h5>
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                this.setState({ is_unlinking: true });
              }}
              disabled={!is_linked}
            >
              Unlink
            </button>
          </div>
          <div className="card-box p-3 mt-1">
            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label>ประเภทออเดอร์</label>
              </div>
              <div className="col-8 col-md-4 mt-2">
                <Radio.Group onChange={this.onSelectOrderType} value={this.state.order_type} disabled={disabled || is_linked}>
                  <Radio value={"dc"}>DC</Radio>
                  <Radio value={"vendor"}>Vendor</Radio>
                </Radio.Group>
              </div>
            </div>
            {this.state.order_type ? (
              <div className="row">
                <div className="col-4 col-md-2 pr-0 mt-2">
                  <label>ออร์เดอร์</label>
                </div>
                <div className="col-8 col-md-4 mt-2">{this.displaySelectOrder()}</div>
                <div className="col mt-2 d-flex align-items-end">{this.displayLinkedOrder()}</div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2"></div>
              <div className="col mt-2">
                <ErrorMessage errorKey={"order_id"} errorMessages={this.state.errors} />
              </div>
            </div>

            {this.displaySelectPO()}

            <div className="row mt-2">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label>เลขที่ PO</label>
              </div>
              <div className="col-8 col-md-4 mt-2">{order_type == "dc" ? inbound_order_item_po && inbound_order_item_po.po_code : order_po_gr.po_code}</div>
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label>วันที่ได้รับ</label>
              </div>
              <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                <DatePicker
                  className="form-control"
                  dateFormat={FORMAT_APP_DATE}
                  selected={order_type == "dc" ? (inbound_order_item_po && inbound_order_item_po.po_date ? new Date(inbound_order_item_po.po_date) : null) : order_po_gr.po_date ? new Date(order_po_gr.po_date) : null}
                  onChange={(date) => {}}
                  disabled={true}
                />
                <ErrorMessage errorKey={"mr_date"} errorMessages={this.state.errors} />
              </div>
            </div>
          </div>

          <h5 className="pb-1 mt-3">ข้อมูลทั่วไป</h5>
          <div className="card-box p-3 mt-1">
            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">{requiredFields["code"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2">
                {this.inputField(request, "code", "text-left")}
                <ErrorMessage errorKey={"code"} errorMessages={this.state.errors} />
              </div>
              <div className="col-4 col-md-2 mt-2">
                <label className="sign-is-required">{requiredFields["mr_date"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                <DatePicker className="form-control" dateFormat={FORMAT_APP_DATE} selected={new Date(this.state.request.mr_date)} onChange={(date) => this.onChangeDate("mr_date", date)} disabled={disabled} />
                <ErrorMessage errorKey={"mr_date"} errorMessages={this.state.errors} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">{requiredFields["mr_subject"]}</label>
              </div>
              <div className="col-8 col-md-10 mt-2">
                {this.inputField(request, "mr_subject", "text-left")}
                <ErrorMessage errorKey={"mr_subject"} errorMessages={this.state.errors} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">{requiredFields["vendor_id"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2">
                <VendorSelect onChange={this.onChangeVendor} vendorId={request.vendor_id} disabled={disabled || is_linked || is_soft_linked} />
                <ErrorMessage errorKey={"vendor_id"} errorMessages={this.state.errors} />
              </div>
              <div className="col-4 col-md-2 mt-2">
                <label className="sign-is-required">{requiredFields["manufacturer_name"]}</label>
              </div>
              <div className="col-8 col-md-4 date-picker-wrapper mt-2">
                {this.inputField(request, "manufacturer_name", "text-left", null, is_linked)}
                <ErrorMessage errorKey={"manufacturer_name"} errorMessages={this.state.errors} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">{requiredFields["contract_id"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2">
                <ContractSelect onChange={this.onChangeContract} contractId={request.contract_id} vendorId={request.vendor_id} disabled={disabled || request.vendor_id === "" || is_linked || is_soft_linked} key={request.vendor_id} />
                <ErrorMessage errorKey={"contract_id"} errorMessages={this.state.errors} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">{requiredFields["contract_no"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2" key={request.vendor_id}>
                {this.inputField(request, "contract_no", "text-left", null, true)}
                <ErrorMessage errorKey={"contract_no"} errorMessages={this.state.errors} />
              </div>
              <div className="col-4 col-md-2 mt-2">
                <label className="sign-is-required">{requiredFields["contract_date"]}</label>
              </div>
              <div className="col-8 col-md-4 date-picker-wrapper mt-2">
                <DatePicker
                  className="form-control"
                  dateFormat={FORMAT_APP_DATE}
                  selected={new Date(this.state.request.contract_date)}
                  onChange={(date) => this.onChangeDate("contract_date", date)}
                  disabled={disabled}
                  readOnly={true}
                  key={request.vendor_id}
                />
                <ErrorMessage errorKey={"contract_date"} errorMessages={this.state.errors} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">{requiredFields["nb_days_to_complete"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2">
                {this.inputField(request, "nb_days_to_complete", "text-left", "Number")}
                <ErrorMessage errorKey={"nb_days_to_complete"} errorMessages={this.state.errors} />
              </div>
              <div className="col-4 col-md-2 mt-2">
                <label className="sign-is-required">{requiredFields["request_date"]}</label>
              </div>
              <div className="col-8 col-md-4 date-picker-wrapper mt-2">
                <DatePicker className="form-control" dateFormat={FORMAT_APP_DATE} selected={new Date(this.state.request.request_date)} onChange={(date) => this.onChangeDate("request_date", date)} disabled={disabled} />
                <ErrorMessage errorKey={"request_date"} errorMessages={this.state.errors} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">{requiredFields["coordinator_name"]}</label>
              </div>
              <div className="col-8 col-md-10 mt-2">
                {this.inputField(request, "coordinator_name", "text-left")}
                <ErrorMessage errorKey={"coordinator_name"} errorMessages={this.state.errors} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">{requiredFields["approver_name"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2">
                {this.inputField(request, "approver_name", "text-left")}
                <ErrorMessage errorKey={"approver_name"} errorMessages={this.state.errors} />
              </div>
              <div className="col-4 col-md-2 mt-2">
                <label className="sign-is-required">{requiredFields["approver_title"]}</label>
              </div>
              <div className="col mt-2">
                {this.inputField(request, "approver_title", "text-left")}
                <ErrorMessage errorKey={"approver_title"} errorMessages={this.state.errors} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">{requiredFields["s3_signature_upload"]}</label>
              </div>
              <div className="col-8 col-md-10 mt-2">
                <ImageUploader
                  file_category={`purchasing_orders/${request.id}/mr_signature`}
                  url={this.state.request.s3_signature_upload}
                  onUploadNewFile={this.onUploadNewSignature}
                  disabled={request.id ? disabled : true}
                  withUniqueKey={false}
                  key={this.state.image_uploader_key}
                />
                {request.id ? "" : <div className="pt-2 color-red">* กดเซฟก่อนแก้ไขไฟล์</div>}
                <div className="pt-2 font-size-13px">ไฟล์ PNG/JPEG ขนาดแนะนำไม่เกิน 300x100 pixel (กว้างxสูง)</div>
                <ErrorMessage errorKey={"s3_signature_upload"} errorMessages={this.state.errors} />
              </div>
            </div>
          </div>

          <h5 className="pb-1 mt-3">ข้อมูลวางบิล (ผู้ผลิต)</h5>
          <div className="card-box p-3 mt-1">
            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label>{vendorBillingFields["tb_po_invoice_nb"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2">
                {this.inputField(request, "tb_po_invoice_nb", "text-left")}
                <ErrorMessage errorKey={"tb_po_invoice_nb"} errorMessages={this.state.errors} />
              </div>
              <div className="col-4 col-md-2 mt-2">
                <label>{vendorBillingFields["tb_design_eng_nb"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                {this.inputField(request, "tb_design_eng_nb", "text-left")}
                <ErrorMessage errorKey={"tb_design_eng_nb"} errorMessages={this.state.errors} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label>{vendorBillingFields["tb_rwt_nb"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2">
                {this.inputField(request, "tb_rwt_nb", "text-left")}
                <ErrorMessage errorKey={"tb_rwt_nb"} errorMessages={this.state.errors} />
              </div>
              <div className="col-4 col-md-2 mt-2 pr-0">
                <label>{vendorBillingFields["tb_received_date"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                <DatePicker
                  className="form-control"
                  dateFormat={FORMAT_APP_DATE}
                  selected={this.state.request.tb_received_date ? new Date(this.state.request.tb_received_date) : null}
                  onChange={(date) => this.onChangeDate("tb_received_date", date)}
                  disabled={disabled}
                />
                <ErrorMessage errorKey={"tb_received_date"} errorMessages={this.state.errors} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label>{vendorBillingFields["tb_remark"]}</label>
              </div>
              <div className="col-8 col-md-10 mt-2">
                {this.inputField(request, "tb_remark", "text-left", "Text")}
                <ErrorMessage errorKey={"tb_remark"} errorMessages={this.state.errors} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  displayStampInfo() {
    let { request, disabled } = this.state;
    return (
      <React.Fragment>
        <div className={this.state.current_tab === MR_TABS.info ? "col-12 offset-lg-1 col-lg-10 px-3 pb-3 block-info" : "d-none"}>
          {/* User Info */}
          <h5 className="pb-1">ข้อความอนุมัติตรวจรับเอกสาร</h5>
          <div className="card-box p-3 mt-1">
            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">{requiredFields["informed_date"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                <DatePicker className="form-control" dateFormat={FORMAT_APP_DATE} selected={request.informed_date ? new Date(request.informed_date) : null} onChange={(date) => this.onChangeDate("informed_date", date)} disabled={disabled} />
                <ErrorMessage errorKey={"informed_date"} errorMessages={this.state.errors} />
              </div>
              <div className="col-4 col-md-2 mt-2">
                <label className="sign-is-required">{requiredFields["inspection_date"]}</label>
              </div>
              <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                <DatePicker className="form-control" dateFormat={FORMAT_APP_DATE} selected={request.inspection_date ? new Date(request.inspection_date) : null} onChange={(date) => this.onChangeDate("inspection_date", date)} disabled={disabled} />
                <ErrorMessage errorKey={"inspection_date"} errorMessages={this.state.errors} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-3">
                <label>ข้อความเริ่มต้น</label>
              </div>
              <div className="col mt-3 new-line">
                {/* เรียนผจ.จส.<br/> */}
                {this.state.inspection_content.replace("<date>", request.informed_date ? formatThaiDate(request.informed_date) : "")}
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">{requiredFields["s3_inspection_sign_url"]}</label>
              </div>
              <div className="col-8 col-md-10 mt-2">
                <ImageUploader
                  file_category="mr_inspection_s3_sign_url"
                  url={this.state.request.s3_inspection_sign_url}
                  onUploadNewFile={this.onUploadNewInspectionSignature}
                  disabled={request.id ? disabled : true}
                  withUniqueKey={false}
                  key={this.state.image_uploader_key}
                />
                {request.id ? "" : <div className="pt-2 color-red">* กดเซฟก่อนแก้ไขไฟล์</div>}
                <div className="pt-2 font-size-13px">ไฟล์ PNG/JPEG ขนาดแนะนำไม่เกิน 300x100 pixel (กว้างxสูง)</div>
                <ErrorMessage errorKey={"s3_inspection_sign_url"} errorMessages={this.state.errors} />
              </div>
            </div>
          </div>
          <div className="block-info-buffer-box"></div>
        </div>
      </React.Fragment>
    );
  }

  displayStationsPage() {
    if (this.state.current_tab === MR_TABS.stations) {
      return (
        <React.Fragment>
          <MrFormStations
            item_category_options={this.state.item_category_options}
            stations={this.state.request.manufacture_request_stations}
            onAddStationList={this.onAddStationList}
            onDeleteStationList={this.onDeleteStationList}
            onChangeStationList={this.onChangeStationList}
            disabled={this.state.disabled || this.state.is_linked || this.state.is_soft_linked}
          />
        </React.Fragment>
      );
    }
  }

  displayStampPage() {
    if (this.state.current_tab === MR_TABS.stamp) {
      return (
        <React.Fragment>
          <MrFormStamp
            onToggleCommittee={this.onToggleCommittee}
            onClickResetCommittee={this.onClickResetCommittee}
            item_category_options={this.state.item_category_options}
            request={this.state.request}
            stations={this.state.request.manufacture_request_stations}
            disabled={this.state.disabled}
            onChangeResponsiblePerson={this.onChangeResponsiblePerson}
          />
        </React.Fragment>
      );
    }
  }

  displayDocumentsPage() {
    if (this.state.current_tab === MR_TABS.documents) {
      return (
        <React.Fragment>
          <div className="offset-lg-1 col-lg-10 px-3">
            <MrFormDocuments mr={this.state.request} />
          </div>
        </React.Fragment>
      );
    }
  }

  render() {
    let { current_tab } = this.state;
    let stateColor = "warning";
    if (this.state.request) {
      switch (this.state.request.state) {
        case MR_STATES.cancelled.name:
          stateColor = "danger";
          break;
        case MR_STATES.submitted.name:
          stateColor = "success";
          break;
        default:
      }
    }
    const display_doc_tab = () => {
      if (this.state.selected_mr_id !== null) {
        return (
          <li className="nav-item">
            <a className={`nav-link px-2 ${current_tab === MR_TABS.documents ? "active" : ""}`} onClick={() => this.onChangeFormTab(MR_TABS.documents)}>
              Preview and Summary
            </a>
          </li>
        );
      } else {
        return null;
      }
    };
    if (!this.state.is_mount) {
      return <Spinner loading={this.state.is_loading} />;
    } else {
      console.log("this.state.request", this.state.request);

      return (
        <React.Fragment>
          <Spinner loading={this.state.is_loading} />
          <div className="pb-3 px-3">
            <div className="row">
              <div className="col-10 pr-0 d-flex flex-row">
                <h2 className="d-none d-md-block p-b-2px">{APP_TITLE_MR}</h2>
                <h5 className="mb-2 pb-1 mt-auto px-2 color-darkblue text-wrap">
                  {this.state.request.code} - {this.state.request.manufacturer_name}
                </h5>
                <span className={`badge badge-pill badge-${stateColor} quo-state-tag`}>{this.state.request.state}</span>
              </div>
              <div className="col-2 text-right pl-0">
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={this.onClickBack}>
                  Back
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <ul className="nav nav-tabs tab-responsive div-hide-scrollbar">
                  <li className="nav-item">
                    <a className={`nav-link px-2 ${current_tab === MR_TABS.info ? "active" : ""}`} onClick={() => this.onChangeFormTab(MR_TABS.info)}>
                      Info
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link px-2 ${current_tab === MR_TABS.stations ? "active" : ""}`} onClick={() => this.onChangeFormTab(MR_TABS.stations)}>
                      Stations
                    </a>
                  </li>
                  {this.state.request.vendor_id ? (
                    <li className="nav-item">
                      <a className={`nav-link px-2 ${current_tab === MR_TABS.stamp ? "active" : ""}`} onClick={() => this.onChangeFormTab(MR_TABS.stamp)}>
                        Committee
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {display_doc_tab()}
                </ul>
              </div>
              <div className="col-12 col-md-6 mt-2">
                <div className="text-right">{this.actionBtns()}</div>
              </div>
            </div>
          </div>
          <form onSubmit={this.handleSubmit}>
            {this.displayCustomerPage()}
            {this.displayStampInfo()}
            {this.displayDocumentsPage()}
            {this.displayStationsPage()}
            {this.displayStampPage()}
            {/* {this.displayStampPage()} */}
            {this.cancelMR()}
            {this.displayBackConfirmation()}
          </form>
          {this.display_confirm_unlink()}
        </React.Fragment>
      );
    }
  }
}
