import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

// Temp
import { QUO_STATES, API_RESOURCES } from "../constants.js";
import { getExpenseTotalCost, getExpenseName, getExpenseUnit } from "./ItemCategories";

import { Multiple, toLocaleStringCus } from "../functions/Number";
import { generateID } from "../functions/Random.js";
import { objectIsEmpty } from "../functions/Object";
import { validateInputFromRequireFields } from "../functions/Validation";
import { solveFeeNew } from "../functions/FeePurchaseCalculation.js";

const POSITION_CATEGORY_FEE = 1000;
export const POSITION_CATEGORY_DISCOUNT = POSITION_CATEGORY_FEE - 1;

export const new_expense = {
  fee_type: 3,
  method_type: 4,
  from_value: 0,
  to_value: null,
  id: null,
  quotation_item_category_id: null,
  item_category_id: null,
  name: "",
  price: null,
  contract_price: null,
  unit: "",
  position: POSITION_CATEGORY_FEE,
  note: "",
  qty: null,
  item_qty: 1,
  is_custom_qty: true,
  is_new: true,
  resource_type: "ItemCategoryFee",
  resource_type_id: null,
  resource_id: null,
};

const requiredFields = {
  quotation_date: "วันที่ใบเสนอราคา",
  approver_name: "ชื่อผู้อนุมัติ",
  approver_title: "ตำแหน่งผู้อนุมัติ",
  "client_station.name": "ชื่อสถานี",
  "client_station.address.district_id": "อำเภอ",
  "client_station.address.sub_district_id": "ตำบล",
  "client_station.address.province_id": "จังหวัด",
};

export function isDisabled(quotation) {
  return !(quotation.state === QUO_STATES.draft.name || quotation.state === QUO_STATES.submitted.name || quotation.state === QUO_STATES.cloned_to_edit.name);
}

export function isItemDiscount(elm) {
  return elm.resource_type === "ItemDiscount";
}

export function isDiscount(elm) {
  return elm.resource_type === "ItemDiscount" || elm.resource_type === "ItemCategoryDiscount";
}

export function isItemExpense(type) {
  return type === "ItemDiscount" || type === "ItemFee";
}

export function isCategoryFee(elm) {
  return elm.resource_type === "ItemCategoryFee";
}

export function isCategoryDiscount(elm) {
  return elm.resource_type === "ItemCategoryDiscount";
}

export function getClientStationName(quotation) {
  if (quotation) {
    if (quotation.client_station) {
      return quotation.client_station.name;
    }
  }
  return "";
}

export function initQuotationItemCategories(quo_item_categories) {
  let new_cate = {};

  quo_item_categories.forEach((cate) => {
    new_cate[cate.item_category_id] = initQuotationItemExpense(cate);
    new_cate[cate.item_category_id].code = new_cate[cate.item_category_id].item_category ? new_cate[cate.item_category_id].item_category.code : null;
  });

  return new_cate;
}

export function initNewFee(item_category_id) {
  // clone fee
  let new_cate_fee = cloneDeep(new_expense);
  // init fee
  new_cate_fee.id = generateID();
  new_cate_fee.item_category_id = item_category_id;

  return new_cate_fee;
}

export function initNewDiscount(item_category_id) {
  // clone discount
  let new_cate_discount = cloneDeep(new_expense);
  // init fee
  new_cate_discount.id = generateID();
  new_cate_discount.item_category_id = item_category_id;
  new_cate_discount.is_new = false;
  new_cate_discount.resource_type = "ItemCategoryDiscount";
  new_cate_discount.position = POSITION_CATEGORY_DISCOUNT;
  new_cate_discount.name = "รายหมวดหมู่";

  return new_cate_discount;
}

export function initExpense(item_category_id, elm, position, type) {
  let expense = {};
  if (isDiscount(type)) {
    expense = cloneDeep(new_expense);
    expense.fee_type = elm.fee_type;
    expense.method_type = elm.method_type;
    expense.name = elm.name;
    expense.price = elm.price;
    expense.unit = elm.unit;
    expense.is_custom_qty = false;
    expense.from_value = elm.from_value;
    expense.to_value = elm.to_value;
    expense.position = position + 100;
  } else {
    expense = cloneDeep(elm);
    if (type === "ItemFee") {
      expense.position = position;
    }
  }

  expense.id = generateID();
  expense.resource_type = type;
  expense.resource_type_id = elm.id;
  expense.resource_id = elm.item_id;
  expense.is_new = false;
  expense.item_qty = 1;
  expense.qty = 0;
  expense.note = "";
  expense.item_category_id = item_category_id;

  return expense;
}

export function initWarrantyItem(quo_item, item_categories) {
  if (!quo_item.warranty_type) {
    quo_item.warranty_type = item_categories[quo_item.item_category_id].item_category.warranty_type;
  }
  if (!quo_item.warranty_value) {
    quo_item.warranty_value = item_categories[quo_item.item_category_id].item_category.warranty_value;
  }

  return quo_item;
}

export function initDaystoInstallation(quo) {
  let { quotation_items } = quo;
  quotation_items.map((item) => {
    if (item.installation_date) {
      let approved_state_date_moment = moment(new Date(quo.approved_state_date));
      let date = new Date(item.installation_date);
      item.days_to_installation = moment(new Date(date)).diff(approved_state_date_moment, "days");
    }
  });

  return quotation_items;
}

export function totalSalesWithVat(total, witholding_tax = 0) {
  if (total) {
    let vat = total * 0.07;
    return total + vat - witholding_tax;
  }
  return 0;
}

export function calTotalSales(quotation, is_contract_price) {
  let { quotation_items, quotation_item_categories } = quotation;
  let total = 0;
  if (quotation_items.length !== 0) {
    const total_expense = getQuotationTotalExpense(quotation_item_categories, is_contract_price)
    const total_item_price = getTotalItemPrice(quotation_items, is_contract_price);
    total = parseInt(total_expense) + parseInt(total_item_price)
  }

  return total;
}

export function calTotalSalesWithVat(quotation, witholding_tax = 0) {
  let total = calTotalSales(quotation);
  return totalSalesWithVat(total, witholding_tax);
}

export function calTotalDiscount(quotation, is_contract_price) {
  let discount = 0;
  let categories = quotation.quotation_item_categories;

  for (var key in categories) {
    discount += getCategoryDiscount(categories[key].item_category_expenses, is_contract_price);
  }

  return discount;
}

export function getCategoryDiscount(item_category_expenses, is_contract_price) {
  let discount = 0;
  if (item_category_expenses !== undefined) {
    discount = sumExpenseTotalMulQty(
      item_category_expenses.filter((exp) => isDiscount(exp) === true),
      is_contract_price
    );
  }
  return discount;
}

export function getCategoryFee(item_category_expenses, is_contract_price) {
  let fee = 0;
  if (item_category_expenses !== undefined) {
    fee = sumExpenseTotalMulQty(
      item_category_expenses.filter((exp) => isDiscount(exp) !== true),
      is_contract_price
    );
  }
  return fee;
}

export function getCategoryTotalExpense(item_category_expenses, is_contract_price = false, is_single = false) {
  let sum = 0;
  if (item_category_expenses !== undefined) {
    sum = item_category_expenses.reduce(function (sum, elm) {
      if (isDiscount(elm)) {
        return sum - getExpenseTotalCost(elm, is_contract_price);
      } else {

        if (is_single && elm.method_type === 1 || is_single && elm.method_type === 2 || is_single && elm.method_type === 5 || !is_single && elm.method_type === 7 || is_single && elm.method_type === 7 && elm.from_value !== is_single.from_value)
          return sum + 0

        return sum + getExpenseTotalCost(elm, is_contract_price);
      }
    }, 0);
  }
  return sum;
}

export function genExpenseOnItemAdded(quotation_item_category, item, item_qty) {
  let cate = quotation_item_category;

  // add item fees
  let position = cate.item_category_expenses.length;
  item.item_fees.forEach((fee, index) => {
    position = position + index;
    fee = initExpense(cate.item_category_id, fee, position, "ItemFee");
    cate.item_category_expenses.push(fee);
  });
  // add item discount
  item.item_discounts.forEach((discount, index) => {
    position = position + index;
    discount = initExpense(cate.item_category_id, discount, position, "ItemDiscount");
    cate.item_category_expenses.push(discount);
  });
  return cate;
}

export function deleteItem(quotation, item_id) {
  let quo = quotation;
  let item_index = quo.quotation_items.findIndex((item) => item.id === item_id);
  let item_category_id = quo.quotation_items[item_index].item_category_id;

  let is_from_parent = quo.quotation_items[item_index].from_parent
  let parent_item_id = quo.quotation_items[item_index].parent_item_id

  // delete its fee and discount
  quo.quotation_item_categories[item_category_id].item_category_expenses = quo.quotation_item_categories[item_category_id].item_category_expenses.filter((exp) => exp.resource_id !== quo.quotation_items[item_index].item_id);

  // delete an item
  quo.quotation_items.splice(item_index, 1);

  let find_parent_items = []

  if (is_from_parent) {
    find_parent_items = quo.quotation_items.filter((item) => item.parent_item_id === parent_item_id)

    if (find_parent_items.length === 0) {
      const parent_index = quo.quotation_items.findIndex(({ item_id }) => item_id === parent_item_id)
      quo.quotation_items.splice(parent_index, 1);
    }
  }


  // remove the item category if there is no item
  let number_of_items = quo.quotation_items.filter((item) => item.item_category_id === item_category_id).length;
  if (number_of_items === 0) {
    delete quo.quotation_item_categories[item_category_id];
  }

  return quo;
}

export function sumTotalMulQty(records, is_contract_price) {

  if (records === undefined || records.is_parent) {
    return 0;
  }

  if (is_contract_price) {
    return records.reduce((sum, elm) => !elm.is_parent ? (sum += Multiple(elm.qty, elm.contract_price)) : sum += 0, 0);
  } else {
    return records.reduce((sum, elm) => !elm.is_parent ? (sum += Multiple(elm.qty, elm.price)) : sum += 0, 0);
  }
}

export function sumExpenseTotalMulQty(records, is_contract_price) {
  if (records === undefined) {
    return 0;
  }

  return records.reduce((sum, elm) => (sum += getExpenseTotalCost(elm, is_contract_price)), 0);
}

export function getCateSuggestedFee(items) {
  if (items === undefined) {
    return 0;
  }

  console.log('items', items)

  return items.reduce((sum, elm) => (sum += Multiple(elm.qty, elm.expense_ratio)), 0);
}

export function validateQuotation(quotation, newState) {
  let errors = {};

  // validate require fields
  errors = validateInputFromRequireFields(quotation, requiredFields);

  // validate fee adjustment
  // if (objectIsEmpty(errors) && newState === QUO_STATES.submitted.name) {
  if (objectIsEmpty(errors)) {
    let categories = quotation.quotation_item_categories;
    let itemsByCategory = _.groupBy(quotation.quotation_items, function (value) {
      return value.item_category_id;
    });


    for (var key in categories) {

      let is_type_7 = null

      categories[key].item_category_expenses.forEach((cate) => {
        if (cate.method_type === 7 && cate.qty > cate.from_value) {
          if (is_type_7) {
            if (cate.qty > is_type_7.from_value && is_type_7.from_value < cate.from_value) {
              is_type_7 = cate
            }
          } else {
            is_type_7 = cate
          }

        }
      })

      const sum_expense = getCategoryTotalExpense(categories[key].item_category_expenses, false, is_type_7 ? is_type_7 : false)

      let item_qty_arr = itemsByCategory[key].map((item) => {
        return parseInt(item.qty) || 0;
      });

      let item_price_arr = itemsByCategory[key].map((item) => {
        return parseInt(item.price) || 0;
      });

      let suggest_fee = solveFeeNew(sum_expense, item_qty_arr, item_price_arr);

      // let suggest_fee = getCateSuggestedFee(itemsByCategory[key]);
      // let expense = getCategoryTotalExpense(categories[key].item_category_expenses);

      if (suggest_fee[3] !== sum_expense) {
        errors["suggest_fee"] = `กรุณาปรับค่าบริการของ '${categories[key].sys_name}' ก่อน Submit หรือ Approve`;
        break;
      }
    }
  }

  return errors;
}

export function validateApproveQuo(quotation, newState) {
  let errors = {};
  if (newState == QUO_STATES.approved.name) {
    let { quotation_item_categories } = quotation;
    Object.keys(quotation_item_categories).forEach((key) => {
      if (quotation_item_categories[key].contract_id == null && quotation_item_categories[key].item_category.dc_id !== 1) {
        errors["no_contracts"] = `หมวดหมู่สินค้า ${quotation_item_categories[key].sys_name} ยังไม่ได้ระบุสัญญาคู่ค้า`;
      }
    });
    console.log("========validate=========");
    console.log(errors["no_contracts"]);
  }
  return errors;
}
export function toExcelCompareTable(quotation) {
  let data = [];
  let end_line = 1;
  let categories = quotation.quotation_item_categories;
  let catFilterVendor = _.filter(categories, function (value) {
    return value.item_category.dc_id == 0;
  });
  catFilterVendor = _.map(catFilterVendor, function (value) {
    return value.item_category_id;
  });
  // quotation.quotation_items = _.filter(quotation.quotation_items, function (value) {
  //   return catFilterVendor.includes(value.item_category_id);
  // });
  let itemsByCategory = _.groupBy(quotation.quotation_items, function (value) {
    return value.item_category_id;
  });
  // header
  // let header = ['ลำดับ', 'หมวดหมู่', 'สินค้า', 'จำนวน', 'ราคาขายต่อหน่วย', 'หน่วย', 'รวม', 'ราคาซื้อต่อหน่วย', 'รวม']
  let font = { sz: "10" };
  let font_red = { sz: "10", color: { rgb: "FFff0000" } };
  let border_color = { style: "thin", color: { rgb: "FF000000" } };
  let border_white = { style: "thin", color: { rgb: "FFFFFFFF" } };
  let border_style = { top: border_color, bottom: border_color, left: border_color, right: border_color };
  let fake_merged_style_r = { top: border_color, bottom: border_color, left: border_color, right: border_white };
  let fake_merged_style_l = { top: border_color, bottom: border_color, left: border_white, right: border_color };
  let fake_merged_style_lr = { top: border_color, bottom: border_color, left: border_white, right: border_white };
  let fill_yellow = { fill: { fgColor: { rgb: "ffffff00" } }, border: border_style, font: font };
  let fill_turq = { fill: { fgColor: { rgb: "ff87e8c9" } }, border: border_style, font: font };
  let fill_pink = { fill: { fgColor: { rgb: "fffbc8f9" } }, border: border_style, font: font_red };
  let style_fill_1 = { fill: { fgColor: { rgb: "ffffe9d9" } }, border: border_style, font: font };
  let style_fill_2 = { fill: { fgColor: { rgb: "ffdee6ef" } }, border: border_style, font: font };
  let style_no_filled = { border: border_style, font: font };
  let title = [
    { title: "", width: { wch: 5 }, style: { border: fake_merged_style_r, font: font } },
    { title: "", width: { wch: 20 }, style: { border: fake_merged_style_lr, font: font } },
    { title: "", width: { wch: 60 }, style: { border: fake_merged_style_lr, font: font } },
    {
      title: "ตารางเปรียบเทียบราคาขายและต้นทุนสินค้า",
      width: { wch: 5 },
      style: { border: fake_merged_style_lr, font: { sz: "12" }, alignment: { horizontal: "center" } },
    },
    { title: "", width: { wch: 15 }, style: { border: fake_merged_style_lr, font: font } },
    { title: "", width: { wch: 10 }, style: { border: fake_merged_style_lr, font: font } },
    { title: "", width: { wch: 10 }, style: { border: fake_merged_style_lr, font: font } },
    { title: "", width: { wch: 15 }, style: { border: fake_merged_style_lr, font: font } },
    { title: "", width: { wch: 10 }, style: { border: fake_merged_style_lr, font: font } },
    { title: "", width: { wch: 10 }, style: { border: fake_merged_style_l, font: font } },
  ];
  let header = [
    { title: "ลำดับ", width: { wch: 5 }, style: style_no_filled },
    { title: "หมวดหมู่", width: { wch: 20 }, style: style_no_filled },
    { title: "สินค้า", width: { wch: 60 }, style: style_no_filled },
    { title: "จำนวน", width: { wch: 5 }, style: style_no_filled },
    { title: "ราคาขายต่อหน่วย", width: { wch: 15 }, style: fill_yellow },
    { title: "หน่วย", width: { wch: 10 }, style: fill_yellow },
    { title: "รวม", width: { wch: 10 }, style: fill_yellow },
    { title: "ราคาซื้อต่อหน่วย", width: { wch: 15 }, style: fill_turq },
    { title: "รวม", width: { wch: 10 }, style: fill_turq },
    { title: "%กำไร", width: { wch: 10 }, style: fill_pink },
  ];

  let total = 0;
  let contract_total = 0;

  let index = 1;

  for (var key in categories) {
    if (catFilterVendor.includes(Number(key))) {
      let fill_color = index % 2 !== 0 ? style_fill_1 : style_fill_2;
      itemsByCategory[key].forEach((item) => {
        let item_total = Multiple(item.price, item.qty);
        let contract_item_total = Multiple(item.contract_price, item.qty);
        total += item_total;
        contract_total += contract_item_total;

        data.push([
          { value: index, style: fill_color },
          { value: categories[key].sys_name, style: fill_color },
          { value: item.name, style: fill_color },
          { value: parseInt(item.qty), style: fill_color },
          { value: item.price, style: fill_yellow },
          { value: item.unit, style: fill_yellow },
          { value: item_total, style: fill_yellow },
          { value: item.contract_price || "", style: fill_turq },
          { value: contract_item_total, style: fill_turq },
          { value: "", style: fill_pink },
        ]);

        index += 1;
        end_line += 1;
      });

      categories[key].item_category_expenses
        .sort((a, b) => (a.position > b.position ? 1 : -1))
        .forEach((elm) => {
          if (elm.price > 0 && !((elm.qty == 0 || elm.item_qty == 0) && !elm.is_custom_qty)) {
            let exp_price = elm.price;
            let exp_total = getExpenseTotalCost(elm, false);
            let exp_contract_price = elm.contract_price;
            let exp_contract_total = getExpenseTotalCost(elm, true);
            if (isDiscount(elm)) {
              exp_price = exp_price * -1;
              exp_total = exp_total * -1;
              exp_contract_price = exp_contract_price * -1;
              exp_contract_total = exp_contract_total * -1;
            }

            data.push([
              { value: "", style: fill_color },
              { value: "", style: fill_color },
              { value: getExpenseName(elm), style: fill_color },
              { value: elm.qty ? parseInt(elm.qty) : "", style: fill_color },
              { value: exp_price || "", style: fill_yellow },
              { value: elm.qty ? getExpenseUnit(elm) : "", style: fill_yellow },
              { value: exp_total || "", style: fill_yellow },
              { value: exp_contract_price || "", style: fill_turq },
              { value: exp_contract_total || "", style: fill_turq },
              { value: "", style: fill_pink },
            ]);

            total += exp_total;
            contract_total += exp_contract_total;
            end_line += 1;
          }
        });
    }
  }

  data.push([
    { value: "", style: style_no_filled },
    { value: "", style: style_no_filled },
    { value: "", style: style_no_filled },
    { value: "", style: style_no_filled },
    { value: "", style: style_no_filled },
    { value: "รวม", style: fill_yellow },
    { value: total, style: fill_yellow },
    { value: "", style: style_no_filled },
    { value: contract_total, style: fill_turq },
    { value: `% ${(((total - contract_total) / contract_total) * 100).toFixed(2)}`, style: fill_pink },
  ]);

  return {
    sheet_name: "ตารางเปรียบเทียบ",
    dataSet: [
      { columns: title, data: [] },
      { columns: header, data: data },
    ],
  };
}

function initQuotationItemExpense(quo_item_category) {
  let de_category = quo_item_category;
  de_category.item_category_expenses = [];
  quo_item_category.quotation_item_expenses.forEach((expense, index) => {
    expense.position = isCategoryDiscount(expense) ? POSITION_CATEGORY_DISCOUNT : index;
    expense.item_category_id = de_category.item_category_id;
    expense.is_new = false;
    de_category.item_category_expenses.push(expense);
  });

  // push new fee to the cloned category
  de_category.item_category_expenses.push(initNewFee(de_category.item_category_id));
  // push new discount to the cloned category if there is no discount
  if (de_category.item_category_expenses.filter((elm) => isCategoryDiscount(elm)).length === 0) {
    de_category.item_category_expenses.push(initNewDiscount(de_category.item_category_id));
  }
  delete quo_item_category["quotation_item_expenses"];
  return de_category;
}

function getQuotationTotalExpense(quotation_item_categories, is_contract_price) {
  let sum_fee = 0;
  for (var key in quotation_item_categories) {
    // const find_type_7 = quotation_item_categories[key]?.item_category_expenses?.find((cate) => cate.method_type === 7 && cate.qty > cate.from_value)

    let find_type_7 = null

    quotation_item_categories[key]?.item_category_expenses.forEach((cate) => {
      if (cate.method_type === 7 && cate.qty > cate.from_value) {
        if (find_type_7) {
          if (cate.qty > find_type_7.from_value && find_type_7.from_value < cate.from_value) {
            find_type_7 = cate
          }
        } else {
          find_type_7 = cate
        }

      }
    })

    sum_fee += getCategoryTotalExpense(quotation_item_categories[key].item_category_expenses, is_contract_price, find_type_7 ? find_type_7 : false);
  }
  return sum_fee;
}

export function getTotalItemPrice(quotation_items, is_contract_price) {
  let sum_item_cost = 0;
  sum_item_cost = sumTotalMulQty(quotation_items, is_contract_price);
  return sum_item_cost;
}
